import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { bl19personOwn } from '../../../app.bl.types';


@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {

  @Input() person: bl19personOwn;
  @Input() me: bl19personOwn;

  @Output() successEmitter = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  setEdit() {
    this.successEmitter.emit("editModeOn");
  }

}
