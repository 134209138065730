<bl19-toolbar></bl19-toolbar>

<div style="padding-top: 70px;" id="contact-heading" class="mat-typography container">
  <h2>
    Kontakt
  </h2>
  <mat-divider id="contact-divider"></mat-divider>
</div>



<div class="container mat-typography" id="contact-content">

  <app-contact-map></app-contact-map>

</div>