<div class="bl19LightBackground">
    <h3>Dankeschön:</h3>
    <div class="layoutColumn" style="max-width: 70%;">
        <p>
            Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a
                href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a
                href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC
                3.0
                BY</a>
        </p>
        <p>
            <a target="_blank" href="https://icons8.com/icons/set/google-podcasts">Icons</a> icon by <a target="_blank"
                href="https://icons8.com">Icons8</a>
            <a target="_blank" href="https://icons8.com/icons/set/spotify--v1">Spotify icon</a> icon by <a
                target="_blank" href="https://icons8.com">Icons8</a>
        </p>
        <p>
            <a href="https://angular.io/">Angular</a>
        </p>
    </div>
</div>