import { Component, OnInit, ViewChild, Inject } from '@angular/core';


import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from "@angular/material/dialog";

import { Observable } from 'rxjs';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'bl19-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput




  // Main task
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;


  snapshot: Observable<any>;


  downloadURL: string = '';
  fileName: string = "";
  fileSize: number = 0;
  duration: number = 0;

  // state for dropzone
  // need to show hovering effect
  isHovering: boolean



  constructor(private dialogRef: MatDialogRef<UploadFileComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }




  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(fileList: FileList) {

    const file = fileList[0];

    this.fileSize = file.size;
    console.log("File size is: " + this.fileSize);


    // to get metadata of the audio-File:
    const audioFile = document.createElement('audio')
    audioFile.addEventListener('loadedmetadata', event => {
      this.duration = Math.round(audioFile.duration);
      console.log(this.duration);
    })
    audioFile.src = URL.createObjectURL(file);

    // // get duration of mp3 file
    // const reader = new FileReader();
    // reader.addEventListener('load', (event) => {
    //   console.log("this is the reader output...")
    //   console.log(event.target.result);
    // })
    // reader.readAsArrayBuffer(file);



    // where to put the file:
    const path = this.data.path + file.name;
    console.log(path);

    // Main task:
    this.task = this.storage.upload(path, file);

    // to get the downloadpath later on
    const ref = this.storage.ref(path);


    // progress:
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();


    // the files download URL
    this.task.snapshotChanges().pipe(
      finalize(() => {
        ref.getDownloadURL().toPromise().then(res => {
          this.downloadURL = res;

          // comunicate to parent component that upload was successfull
          // and close the dialog
          const ret = { url: this.downloadURL, file: path, fileSize: this.fileSize, duration: this.duration }
          this.dialogRef.close(ret);

        }, err => {

          console.error('uploadSermonComponent: error receive the download URL')

          // Close the dialog, even if it was not successfull on receiving the download URL
          // 
          //const ret = { url: this.downloadURL, file: path, fileSize: this.fileSize, duration: this.duration }
          this.dialogRef.close(err);
        });
      })
      // no need to unsubscribe, because of finalize
    ).subscribe();
  }


  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }



  save() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
