import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';



import { UsersService } from 'src/app/services/users.service';
import { AuthService } from '../../../services/auth.service';
import { bl19personOwn } from '../../../app.bl.types';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class MembersComponent implements OnInit {

  @Input() location: string = null;


  membersSub: Subscription;
  members: bl19personOwn[];

  meSub: Subscription;
  me: bl19personOwn;

  displayedColumns: string[] = ['name'];
  expandedPerson: bl19personOwn | null;

  dataSource = new MatTableDataSource();

  constructor(
    private usersServ: UsersService,
    private authServ: AuthService,
  ) { }


  ngOnInit() {
    // -> Subscribe to observable
    this.membersSub = this.usersServ.getListsObs(this.location).subscribe(members => {
      this.members = members;
      this.dataSource.data = this.members;
    });

    this.meSub = this.authServ.getMeObs().subscribe(me => {
      this.me = me;
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.membersSub) {
      this.membersSub.unsubscribe();
    }
    if (this.meSub) {
      this.meSub.unsubscribe();
    }
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}