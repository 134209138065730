import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore'
import { bl19news, bl19singleQuery, bl19dataRichText } from '../app.bl.types';


@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private afs: AngularFirestore) { }


  getSingleNewsRichText(uid: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let newsRichText: string;

      const docNewsText = this.afs.doc<bl19dataRichText>('web/content/news/' + uid + '/more/data');

      docNewsText.get().toPromise().then(res => {
        if (res.data()) {
          resolve(res.data());
        } else {
          resolve('');
        }

      }, err => {
        console.error('NewsService: Error on downloading News RichText: ' + err);
      });
    });
  }

  updateSingleNewsRichText(uid: string, richTextDoc: bl19dataRichText): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const docRefNewsText = this.afs.doc<bl19dataRichText>('web/content/news/' + uid + '/more/data');

      console.log("NewsService: Update RichTextObject: " + richTextDoc);

      docRefNewsText.set(richTextDoc).then(res => {
        resolve(res);
      }, err => {
        console.error('NewsService: Error on updating RichText-Object: ' + err);
        reject(err);
      });
    });
  }

  getSingleNews(uid: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let news: bl19news;

      const docNews = this.afs.doc<bl19news>('web/content/news/' + uid);

      docNews.get().toPromise().then(res => {
        news = res.data();
        resolve(news);
      }, err => {
        console.error('NewsService: Error on downloading Single News: ' + err);
        reject(err);
      });
    });
  }

  getAllNewsByQuery(queryList?: bl19singleQuery[], limit?: number, startAfter?: Date): Promise<Array<bl19news> | any> {
    return new Promise<Array<bl19news>>((resolve, reject) => {
      let newsArray: Array<bl19news> = [];

      let collNews = this.afs.collection<bl19news>('web/content/news').ref
        .limit(limit || 2).orderBy('dateUpdated', 'desc');


      if (queryList) {
        queryList.forEach(elem => {
          collNews = collNews.where(elem.parameter, '==', elem.value);
        });
      }

      if (startAfter) {
        collNews = collNews.startAfter(startAfter);
      }

      collNews.get().then(res => {
        res.docs.forEach(doc => {
          newsArray.push(doc.data());
        });
        resolve(newsArray);
      }, err => {
        console.error('NewsService: Error on downloading all news: ' + err);
        reject(err);
      });

    })
  }


  updateNews(news: bl19news, uid?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      // set the Date and Time when the update has been performed
      news.dateUpdated = new Date();

      // if there is a sermon unique Identifier
      // we have to update an existing sermon
      if (uid) {
        const docNews = this.afs.doc<bl19news>('web/content/news/' + uid);
        docNews.update(news).then(res => {
          console.log('NewsService: successfully updated News: ' + uid);
          resolve(res);
        }, err => {
          console.error('NewsService: Error on updating News with UID: ' + uid + ' error: ' + err);
          reject(err);
        });
      } else {
        // if ther is no news unique Identifier
        // we have to create a new news
        const collNews = this.afs.collection<bl19news>('web/content/news');
        collNews.add(news).then(res => {
          console.log('NewsService: Successfully added new News: ' + res.id);

          // now download the new uploaded doc:
          const docNews = this.afs.doc<bl19news>('web/content/news/' + res.id);
          docNews.get().toPromise().then(res => {
            let result: bl19news = res.data();

            // add the id as sui and then send this back to the caller
            result.uid = res.id;
            resolve(result);
          }, err => {
            console.error('NewsService: Error on downloading most recent added News: ' + err);
          });
        }, err => {
          console.error('NewsService: Error on adding new News: ' + err);
          reject(err);
        });
      }
    });
  }



  deleteNews(uid: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const refNewsDoc = this.afs.doc<bl19news>('web/content/news/' + uid);

      refNewsDoc.delete().then(res => {
        console.log("News deleted, UID: " + uid);
      }, err => {
        console.error("Error on deleting news: " + err);
      });
    });
  }



}
