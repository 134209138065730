<div>
  <mat-accordion multi="true">


    <!--
      TODO: Fix this
    -->
    <!-- Panel "Leitunsteam" -->
    <!-- <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>Leitungsteam</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <bl19-leaders></bl19-leaders>
      </ng-template>

    </mat-expansion-panel> -->

    <mat-expansion-panel [expanded]=true>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>Wer wir sind</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div style="text-align: justify;">
          <p>
            Wir nennen uns Bibelgemeinde.
            Das bedeutet nicht, dass wir uns für besser oder biblischer halten als andere Christen.
            Es soll vielmehr ein Anspruch und eine Aufforderung an uns selber sein.
            Wir halten die Bibel für das geschriebene Wort unseres Schöpfers.
            Deshalb gibt es keine bessere Adresse, wenn wir Fragen zum Leben haben.
          </p>

        <p>
            Der Kirchenvater Augustinus (4. Jhd.) sagte: Ecclesia semper reformanda. (Lateinisch: die Kirche muss
            beständig reformiert werden)
            So wollen wir als Kirchengemeinde immer wieder in Gottes Wort schauen und daraus Leben für uns schöpfen.
        </p>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <!-- Panel "Glaubensbekenntnis" -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>Glaubensbekenntnis</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>


      <ng-template matExpansionPanelContent>
        <div style="text-align: justify">
          <h3>Einleitung</h3>
          <p>
            Wir verstehen uns als eine christliche Gemeinde im biblischen Sinne.
            Das heißt, dass wir uns zur christlichen Lehre bekennen und aus ihr
            unsere Identität gewinnen. Obwohl wir als Gemeinde z.B. auch in der
            Form eines gemeinnützigen Vereins bekannt sind, ist der biblische
            Gedanke der Kirche unsere entscheidende Leitlinie.
          </p>
          <p>
            Wir verstehen als christliche Lehre das, was aus der Bibel gewonnen
            und auf das eigene Leben angewandt wird. Die Reformation sprach
            vom <b>sola scriptura ("allein die Schrift")</b>, und dieses Prinzip gilt für
            unsere Entscheidungen und auch für die Formulierung unseres
            Glaubens. Die Bibel steht somit über jeder Tradition und ist alleinige
            Grundlage für alles, was Glauben und Glaubensleben betrifft. Die
            Bibel identifizieren wir nach Lk 1, 2 und Apg 2, 42 mit dem, was die
            Propheten und die Apostel aufgrund der Einsicht des Heiligen Geistes
            gelehrt und unter seiner Leitung aufgeschrieben haben.
          </p>
          <p>
            Damit verstehen wir uns auch als eine Gemeinde, die in einer
            Tradition mit allen Christen steht, die sich auf eben diese Lehre der
            Apostel bezieht. So wie die ersten Gemeinden dem mündlichen
            Zeugnis der Jünger vertrauten, so vertrauen wir dem schriftlichen
            Zeugnis der Jünger - und sind dadurch selbst Jünger Jesu. Denn was
            wir in der Bibel von Jesus lesen, hat in unserem Leben seine Wahrheit
            erwiesen. Diese Kraft in der Botschaft von Jesus verbindet uns mit
            jeder christlichen Gemeinschaft, die ebenfalls aus dieser Kraft lebt.
          </p>
          <p>
            Schon immer haben Christen ihren biblischen Glauben in eigene
            Worte gekleidet. Die Glaubensbekenntnisse sind ein Versuch, die
            biblischen Kernwahrheiten in kurzen Sätzen auszusagen. Damit will
            man nicht lebendige Wahrheit in ein steifes Korsett zwängen - man
            stellt vielmehr die Mitte seines Glaubens fest und kann sich immer
            wieder auf sie zurückbesinnen.
          </p>
          <p>
            Im folgenden versuchen wir, unseren Glauben mithilfe des
            <b>Apostolischen Glaubensbekenntnisses</b> zu bekennen.
          </p>
        </div>

        <mat-divider></mat-divider>
        <div style="text-align: center">
          <h3>Das apostolische Glaubensbekenntnis</h3>

          <p>
            <b>Ich glaube an Gott</b><br>
            den Vater, den Allmächtigen,<br>
            den Schöpfer des Himmels und der Erde,
          </p>

          <p>
            <b>und an Jesus Christus</b><br>
            seinen eingeborenen Sohn, unseren Herrn <br>
            empfangen durch den Heiligen Geist, <br>
            geboren von der Jungfrau Maria, <br>
            gelitten unter Pontius Pilatus, <br>
            gekreuzigt, gestorben und begraben, <br>
            hinabgestiegen in das Reich des Todes, <br>
            am dritten Tage auferstanden von den Toten, <br>
            aufgefahren in den Himmel; <br>
            er sitzt zur Rechten Gottes, des allmächtigen Vaters. <br>
            Von dort wird er kommen, <br>
            zu richten die Lebenden und die Toten.
          </p>

          <p>
            <b>Ich glaube an den Heiligen Geist</b><br>
            die heilige christliche Kirche, <br>
            Gemeinschaft der Heiligen, <br>
            Vergebung der Sünden, <br>
            Auferstehung der Toten <br>
            und das ewige Leben. <br>
            Amen
          </p>

          <mat-divider></mat-divider>

          <h3>Ich glaube an Gott:</h3>
          <p style="margin-top: 70px"><b>den Vater</b>
          </p>

          <p>Gott ist der Vater Jesu Christi; die beiden sind eins <br>
            <i>Johannes 10,30; Matthäus 3,17</i>
          </p>

          <p>Gottes Vaterschaft ist an Jesus gebunden <br>
            <i>Johannes 8,42</i>
          </p>

          <p>Durch Jesus ist Gott auch unser Vater geworden <br>
            <i>2Korinther 6,18</i>
          </p>

          <p>Durch Jesus können wir ihn vertrauensvoll als Vater anrufen und verherrlichen <br>
            <i>Römer 8,15; Galater 4,6; Römer 15,6</i>
          </p>

          <p>Der Vater wird geehrt, indem sein Sohn Jesus geehrt wird <br>
            <i>Johannes 5,23; Philipper 2,11</i>
          </p>

          <p>Er ist der Vater in urbildlicher Weise - von ihm kommt alle wahre und gute Vaterschaft <br>
            <i>Epheser 3,15, Hebräer 12,6-7</i>
          </p>

          <p>Er ist der Schöpfer - so ist er in erweitertem Sinne auch der Vater der gesamten Schöpfung <br>
            <i>Apostelgeschichte 17,29</i>
          </p>

          <p>Durch Jesus können wir ihn vertrauensvoll als Vater anrufen und verherrlichen <br>
            <i>Römer 8,15; Galater 4,6; Römer 15,6</i>
          </p>


          <p style="margin-top: 70px"><b>den Allmächtigen</b></p>

          <p>Gott ist Adonai, der allmächtige Herr: <br>
          </p>

          <p>Er erschafft in seiner Allmacht <br>
            <i>1.Mose 2,4</i>
          </p>

          <p>Er beherrscht in seiner Allmacht <br>
            <i>Hiob 38-39</i>
          </p>

          <p>Er erwählt in seiner Allmacht <br>
            <i>1.Mose 15,7; 1.Mose 17,1</i>
          </p>

          <p>Er befreit in seiner Allmacht <br>
            <i>2.Mose 6,2-3</i>
          </p>

          <p>Er gibt Gebote in seiner Allmacht <br>
            <i>2.Mose 20,2ff</i>
          </p>

          <p>Er richtet in seiner Allmacht <br>
            <i>Hesekiel 6-7</i>
          </p>

          <p>Er rettet in seiner Allmacht <br>
            <i>Jesaja 43,11; Jeremia 32,27</i>
          </p>

          <p>Seine Allmacht ist eine Macht des Rechtes: Gott ist Liebe <br>
            <i>1.Johannes 4,8</i>
          </p>

          <p> Seine Allmacht und Kraft wird offenbar am Kreuz<br>
            <i> Matthäus 27,39-40; Philipper 2,6-8; 1.Korinther 1,18 </i>
          </p>

          <p> Er ist Herr und Vater seiner Allmacht <br>
            <i> Römer 14,11 </i>
          </p>



          <p style="margin-top: 70px"><b>den Schöpfer des Himmels und der Erde</b></p>

          <p> Gott schafft die Realität durch seinen Willen, er stellt sich die Schöpfung gegenüber <br>
            <i> Psalm 33,6; Römer 4,17 </i>
          </p>

          <p> Die Schöpfung ist sehr gut <br>
            <i> 1.Mose 1,31 </i>
          </p>

          <p> Die Schöpfung wird ununterbrochen von ihm erhalten <br>
            <i> Johannes 5,17 </i>
          </p>

          <p> Die Schöpfung ist ein Hinweis auf Gott den Schöpfer <br>
            <i> Römer 1,20 </i>
          </p>

          <p> Gott ist als Schöpfer bereits der Erlöser (der siebte Tag als Ruhetag) <br>
            <i> Hebräer 4,4-10 </i>
          </p>

          <p> Gott ist auch der Erlöser der Schöpfung <br>
            <i> Römer 8,20-22 </i>
          </p>


          <mat-divider></mat-divider>

          <h3>und an Jesus Christus</h3>
          <p style="margin-top: 70px"><b>seinen eingeborenen Sohn, unseren Herrn</b>
          </p>

          <p> Er ist Gottes Sohn <br>
            <i> Markus 1,9-11; Markus 9,7 </i>
          </p>

          <p> Er ist der erwartete Messias (Christus) <br>
            <i> Markus 8,29 </i>
          </p>

          <p> Er ist Gottes gültiges Wort und Gott selbst (Adonai) <br>
            <i> Johannes 1,1; Philipper 2,6-11 </i>
          </p>

          <p> Das Wort wurde Fleisch <br>
            <i> Johannes 1,14 </i>
          </p>

          <p> Er war ganz und gar Mensch <br>
            <i> 1.Johannes 4,3; 1.Timotheus 3,16 </i>
          </p>

          <p> Er ist ewig und doch Teil der Geschichte, Gott und Mensch zugleich <br>
            <i> Johannes 1,1-3; 1.Johannes 3,5 </i>
          </p>

          <p> Er ist ohne Sünde geblieben - vollkommen, gnädig und gerecht <br>
            <i> Lukas 1,31; Hebräer 13,8; Hebräer 4,15; Johannes 1,14 </i>
          </p>

          <p> Er lebte die Göttliche Vollmacht aus <br>
            <i> Matthäus 9,6 </i>
          </p>

          <p> Er war schon immer Gott und wurde nicht geschaffen <br>
            <i> Johannes 8,38; Johannes 1,3; Römer 11,36 </i>
          </p>


          <p style="margin-top: 70px"><b>empfangen durch den Heiligen Geist, geboren von der Jungfrau Maria</b></p>

          <p> Er wurde empfangen durch den Heiligen Geist <br>
            <i> Lukas 1,35 </i>
          </p>

          <p> Er wurde geboren von der Jungfrau Maria <br>
            <i> Lukas 1,31-32; Jesaja 7,14; Johannes 1,14 </i>
          </p>

          <p> Er wurde vom Vater gesandt <br>
            <i> Johannes 5,37 </i>
          </p>

          <p> Er begab sich in die menschliche Situation <br>
            <i> Galater 4,4 </i>
          </p>

          <p> Er gehörte immer zu seinem Vater <br>
            <i> Lukas 2,49 </i>
          </p>



          <p style="margin-top: 70px"><b>gelitten unter Pontius Pilatus, gekreuzigt, gestorben und begraben,
              hinabgestiegen
              in das Reich des
              Todes</b></p>

          <p> Er war das Lamm Gottes, das die Sünden der Welt wegnimmt <br>
            <i> Johannes 1,29 </i>
          </p>

          <p> Er nahm unsere Sünde auf sich, damit wir seine Gerechtigkeit haben dürfen <br>
            <i> 2.Korinther 5,20-21 </i>
          </p>

          <p> Er litt für unsere Sünden und ist für uns gestorben <br>
            <i> Jesaja 53 </i>
          </p>

          <p> DAbei litt er mit seinem ganzen Wesen. Er hat sich unter das menschliche Gericht gestellt
            und ist in das Totenreich gegangen. <br>
            <i> Markus 15 </i>
          </p>

          <p> In seinem Tod liegt die Erlösung, in seinem Sterben unser Leben <br>
            <i> Johannes 3,16; Johannes 5,24 </i>
          </p>

          <p> In seinem Leiden und Sterben zeigte der Vater seinen Gehorsam gegenüber dem Vater <br>
            <i> Johannes 5,30; Jesja 53,4-5 </i>
          </p>

          <p> Durch sein Leiden und Sterben sind wir vor Gott gerecht gesprochen und können mit ihm Gemeinschaft haben
            <br>
            <i> Johannes 1,11; Galater 3,13 </i>
          </p>



          <p style="margin-top: 70px"><b>am dritten Tage auferstanden von den Toten</b></p>

          <p> Er ist als Erster und wahrhaft auferstanden <br>
            <i> 1.Korinther 15,21; Apostelgeschichte 26,23 </i>
          </p>

          <p> Ebenso werden auch wir auferstehen <br>
            <i> 1.Korinther 15,23 </i>
          </p>

          <p> Gott beendet das "Geschäft" der Sünde und gibt ewiges Leben <br>
            <i> Römer 6,23 </i>
          </p>

          <p> Jesus Christus wurde durch die Auferstehung beglaubigt und dadurch auch Gottes Heilswerk <br>
            <i> Apostelgeschichte 17,31 </i>
          </p>

          <p> Jesus hat Macht über Tod und Leben - und kann uns so Leben geben <br>
            <i> Johannes 11,25 </i>
          </p>

          <p> Das alles musste geschehen, so wie die Propheten es gesagt hatten <br>
            <i> Lukas 24, 25-27 </i>
          </p>

          <p> Er hat dem Tod die Macht genommen und ewiges Leben gebracht <br>
            <i> 2.Timotheus 1,10 </i>
          </p>

          <p> Jesus ist auferstanden und hat den Tod überwunden (und damit seine Sündlosigkeit bezeugt) <br>
            <i> 1.Korinther 15,20-23; Römer 6,9+23 </i>
          </p>



          <p style="margin-top: 70px"><b>aufgefahren in den Himmel; er sitzt zur Rechten Gottes des allmächtigen
              Vaters</b>
          </p>

          <p> Er ist in den Himmel aufgefahren (von wo er gekommen ist) und sitzt jetzt zur Rechten Gottes <br>
            <i> 1.Petrus 3,22 </i>
          </p>

          <p> Er ging, um uns eine Wohnung zu bereiten <br>
            <i> Johannes 14,3 </i>
          </p>

          <p> Er ging, um uns seinen Geist zu senden <br>
            <i> Johannes 14,16 </i>
          </p>

          <p> Alles, Engel, Gewalten und Mächte, ist ihm unterworfen <br>
            <i> Psalm 110,1; Philipper 2,9-11 </i>
          </p>

          <p> Durch ihn wirkt der Vater alles <br>
            <i> Johannes 14,16 </i>
          </p>

          <p> Jesus ist unser Fürsprecher und tritt für uns ein <br>
            <i> 1.Johannes 2,1; Hebräer 4,16 </i>
          </p>

          <p> Jesus ist verherrlicht und wir gehören als sein Leib zu ihm - er ist unser Haupt <br>
            <i> Epheser 1,22f </i>
          </p>


          <p style="margin-top: 70px"><b>von dort wird er kommen, zu richten die Lebenden und die Toten</b></p>

          <p> Er wird - für alle sichtbar - wiederkommen <br>
            <i> Hebräer 9,10 </i>
          </p>

          <p> Seine Wiederkunft und unsere Auferstehung gehören zusammen; Sie bedeutet
            unsere Herrschaft mit Christus <br>
            <i> 1.Korinther; Offenbarung 20,6 </i>
          </p>

          <p> Seine Wiederkunft ist der Widerpart zu seiner Himmelfahrt <br>
            <i> Apostelgeschichte 1,11 </i>
          </p>

          <p> Es werden sich vor Jesus alle Knie beugen <br>
            <i> Offenbarung 5,13; Philipper 2,10-11 </i>
          </p>

          <p> Alle werden ihn sehen - alle werden verstehen, wer da kommt <br>
            <i> Offenbarung 1,7 </i>
          </p>

          <p> Jesus wird richten <br>
            <i> Apostelgeschichte 17,31 </i>
          </p>

          <p> Jesus wird kommen, um alle diejenigen zu sich zu holen, die auf ihn warten <br>
            <i> Jakobus 5,8 </i>
          </p>


          <mat-divider></mat-divider>

          <h3>und an den heiligen Geist</h3>
          <p style="margin-top: 70px"><b>Ich glaube an den Heiligen Geist</b>
          </p>

          <p> Der Heilige Geist ist eine Person - er kann belogen oder betrübt werden <br>
            <i> Apostelgeschichte 5,3; Epheser 4,20; 1.Thessalonicher 5,19 </i>
          </p>

          <p> Der Heilige Geist ist unser Lehrer, Helfer, Tröster, Beistand, Retter <br>
            <i> Johannes 14,16-17+26 </i>
          </p>

          <p> Der Heilige Geist ist der Geist der Wahrheit - in dieser leitet er uns <br>
            <i> Johannes 16,13 </i>
          </p>

          <p> Der Heilige Geist ist der Vertreter unserer Gebete <br>
            <i> Römer 8,27 </i>
          </p>

          <p> Der Heilige Geist und sein Werk ist ungreifbar wie der Wind <br>
            <i> Johannes 3,8 </i>
          </p>

          <p> Der Heilige Geist bleibt bei uns <br>
            <i> Johannes 14,16 </i>
          </p>

          <p> Der Heilige Geist ist das Unterpfand Gottes für unsere Erlösung <br>
            <i> 2.Korinther 1,21-22 </i>
          </p>




          <p style="margin-top: 70px"><b>die heilige christliche Kirche, Gemeinschaft der Heiligen</b></p>

          <p> Die Kirche ist überall dort, wo Mensch in Jesu Namen versammelt sind <br>
            <i> Matthäus 18,20 </i>
          </p>

          <p> Ein jedes Bekenntnis zu Christus ist vom Heiligen Geist gewirkt <br>
            <i> 1.Johannes 4,1-4; 1.Korinther 12,3 </i>
          </p>

          <p> Der Heilige Geist vereint die Gemeinde und macht sie zum Leib Christi <br>
            <i> 1.Korinther 12,13 </i>
          </p>

          <p> Der Heilige Geist sorgt für die Gemeinschaft, indem er Gaben gibt <br>
            <i> 1.Korinther 12,11 </i>
          </p>

          <p> Der Heilige Geist vertritt uns im Gebet vor Gott <br>
            <i> Römer 8,26 </i>
          </p>

          <p> Der Heilige Geist gibt Gemeinschaft, Botschaft des Evangeliums, Wahrheit <br>
            <i> Apostelgeschichte 2,42 </i>
          </p>

          <p> Durch den Heiligen Geist können wir in der Liebe leben und gemeinsam Gott loben <br>
            <i> Galater 5,22-23 </i>
          </p>

          <p> Der Heilige Geist lebt in uns, damit wir nach ihm leben sollen <br>
            <i> Johannes 16,13 </i>
          </p>

          <p> Der Heilige Geist überführt uns von Sünde <br>
            <i> Johannes 16,8 </i>
          </p>

          <p> Der Heilige Geist gibt uns Kraft zum Zeugnis <br>
            <i> Apostelgeschichte 1,8 </i>
          </p>

          <p> Der Heilige Geist arbeitet für die Gemeinschaft an jedem Einzelnen <br>
            <i> 1.Korinther 12,4 </i>
          </p>

          <p> Der Heilige Geist gibt die Gewissheit des Ewigen Lebens <br>
            <i> Epheser 1,13-14 </i>
          </p>





          <p style="margin-top: 70px"><b>Vergebung der Sünden, Auferstehung der Toten und das ewige Leben</b></p>


          <p> Die Vergebung der Sünden wird dem Glaubenden geschenkt <br>
            <i> Apostelgeschichte 10,43 </i>
          </p>

          <p> Unsere Auferstehung wird durch den Heiligen Geist bewirkt <br>
            <i> Römer 8,11 </i>
          </p>

          <p> Der Heilige Geist gibt das Leben <br>
            <i> Johannes 6,63 </i>
          </p>

          <p> Der Heilige Geist bewirkt unsere Wiedergeburt und ihre Früchte <br>
            <i> Johannes 3,5-6; Galater 5,22ff </i>
          </p>

          <p> Die Kraft des Heiligen Geistes macht uns in der HOffnung auf das Ewige Leben fest <br>
            <i> Römer 15,13 </i>
          </p>

          <p> Der Heilige Geist macht uns zu Gottes ewigem Eigentum - unser ewiges Leben <br>
            <i> Epheser 1,14 </i>
          </p>

          <p> Das Ewige Leben wird ein Leben, von dem wir uns keine Vorstellung machen können <br>
            <i> 1.Korinther 2,9 </i>
          </p>

          <p> Das Ewige Leben wird ein Leben der wahrhaftigen Anbetung Gottes <br>
            <i> Offenbarung 19,1-4 </i>
          </p>



        </div>
      </ng-template>

    </mat-expansion-panel>


    <!-- Panel "Finanzen" -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>Finanzen</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>Die ECG-Günzburg gehört zum Bund Taufgesinnter Gemeinden mit Sitz in Detmold.</p>
        <p>Als Freikirche erhalten und erheben wir keine Kirchensteuern oder ähnliches.
          Wir finanzieren uns ausschließlich über freiwillige
          Spenden. Die finanziellen Mittel werden unter Aufsicht
          des Leitungsteams und des Finanzvorstandes
          entsprechend der Ziele und Aufgaben der Gemeinde eingesetzt.
        </p>
        <p>
          Danke für jeden der uns finanziell unterstützt!
        </p>
        <p>
          Wir sind als Freikirche als gemeinnützig anerkannt und steuerlich begünstigt. Wer uns spendet,
          kann dies steuerlich geltend machen.
          Ein Vermerk eurer Adresse im Verwendungszweck ist empfehlenswert.
          Ihr erhaltet dann am Jahresende eine Spendenbescheinigung.
        </p>

        <mat-divider></mat-divider>

        <p><b>Bitte verwende folgendes Konto für deine Spende:</b></p>
        <p>
          ECB e.V. <br>
          IBAN: DE19 7206 9043 0006 1337 70 <br>
          BIC: GENODEF1GZ2 <br>
          VR-Bank Donau-Mindel eG <br>
          <br>
          Verwendungszweck: Spende + Name + Adresse
        </p>
      </ng-template>

    </mat-expansion-panel>

  </mat-accordion>
</div>