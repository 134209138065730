import { Component, OnInit, Input } from '@angular/core';

import { bl19sermonData } from 'src/app/app.bl.types';
import { SermonService } from 'src/app/services/sermon.service';


@Component({
  selector: 'bl19-sermon-single',
  templateUrl: './sermon-single.component.html',
  styleUrls: ['./sermon-single.component.scss']
})
export class SermonSingleComponent implements OnInit {

  @Input() sermon?: bl19sermonData;
  @Input() suid?: string = 'empty';

  editMode: boolean = false;

  constructor(private serServ: SermonService,
  ) { }

  ngOnInit(): void {
    if (this.suid != 'empty') {
      this.serServ.getSingleSermon(this.suid).then(res => {
        this.sermon = res;
      }, err => {
        console.error(err);
      });
    }
  }
}
