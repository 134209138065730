<div *ngIf="!news" class="blcenterelement">
    <mat-spinner></mat-spinner>
</div>


<div *ngIf="news">
    <div *ngIf="!editMode">
        <bl19-news-single-data [news]="news" (editEmitter)="editMode = $event"></bl19-news-single-data>
    </div>


    <div *ngIf="editMode" style=" padding: 10%;">
        <bl19-news-single-edit [news]="news" (editEmitter)="editMode = $event"></bl19-news-single-edit>
    </div>
</div>