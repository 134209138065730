<div *ngIf="newsList" class="container">
    <div class="carousel">
        <div *ngFor="let news of newsList; let i = index">
            <img *ngIf="i === currentNews" src="{{news.picture}}" alt="" class="slide" @carouselAnimation
                style="background-color: black;">

            <div *ngIf="i === currentNews" class="centered clickable" @carouselAnimation>
                <div class="bl-welcome-bright">
                    <div class="newsLinks " style="margin: auto;">


                        <div style="padding-left: 35px; padding-right: 35px;" mat-button *ngIf="news.heading" [routerLink]="'/news'" [queryParams]="{uid: news.uid}" mat-button>
                            <h1>
                                {{news.heading}}
                            </h1>
                        </div>

                        <div *ngIf="news.text" [routerLink]="'/news'" [queryParams]="{uid: news.uid}">
                            <mat-divider class="bl-divider-white"></mat-divider>
                        </div>

                        <div mat-button *ngIf="news.text" [routerLink]="'/news'" [queryParams]="{uid: news.uid}">
                            <h2 style="padding-left: 35px; padding-right: 35px; padding-bottom: 5px;">
                                {{news.text}}
                            </h2>
                        </div>

                        <!-- <button *ngIf="news.link" mat-button (click)="useLink(news.link)">
                            <mat-icon aria-hidden="false" aria-label="link icon">link</mat-icon>
                        </button> -->

                    </div>
                </div>
            </div>

            <div *ngIf="i === currentNews" class="bottom-center" @carouselAnimation>
                <div class="carousel-dots">
                    <span class="dot" *ngFor="let dot of newsList; let cnt = index" [ngClass]="{'active': cnt===i}"
                        (click)="chooseNews(cnt)"></span>
                </div>
                <mat-progress-bar *ngIf="runningTimer" mode="determinate" value="{{currentTime$}}" @carouselAnimation>
                </mat-progress-bar>
            </div>

        </div>

        <!-- controls -->
        <button class="control prev" (click)="onPreviousClick()">
            <span class="arrow left"></span>
        </button>
        <button class="control next" (click)="onNextClick()">
            <span class="arrow right"></span>
        </button>
    </div>
</div>