import { Component, OnInit, Input } from '@angular/core';

import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { GroupsService } from 'src/app/services/groups.service';
import { bl19personSmall, bl19personOwn } from 'src/app/app.bl.types';

import { AuthService } from 'src/app/services/auth.service';
import { AddFromPersonOwnComponent } from '../../users/add-from-person-own/add-from-person-own.component';


@Component({
  selector: 'bl19-group-members',
  templateUrl: './group-members.component.html',
  styleUrls: ['./group-members.component.scss']
})
export class GroupMembersComponent implements OnInit {

  @Input() uid: string;

  showMembers: boolean = false;

  groupMembers: Array<bl19personSmall>;


  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  constructor(private grServ: GroupsService,
    private authServ: AuthService,
    private dialog: MatDialog) { }


  ngOnInit() {
    this.getUsers();

    console.log(this.uid);

    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });
  }

  getUsers() {
    this.groupMembers = null;

    if (this.uid) {
      this.grServ.getUsersInGroup(this.uid).then(res => {
        this.groupMembers = res;
      },
        err => {
          this.groupMembers = null;
        });
    }

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }

  deleteUser(userToDelete: bl19personSmall) {
    this.grServ.removeUserFromGroup(this.uid, userToDelete).then(res => this.getUsers());
  }


  callAddMember() {
    const dialogRef = this.dialog.open(AddFromPersonOwnComponent, {
      width: '300px'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.forEach(member => {
          this.grServ.addUserToGroup(member, this.uid).then(res => {
            this.getUsers();
          }, err => console.log(err));
        });
      }
    });
  }

}
