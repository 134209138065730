<div class="bl-border">
    <div style="margin-left: 10px">

        <!-- Show the  Persons Data -->
        <div *ngIf="!edit">
            <app-user-data [person]="person" [me]="me" (successEmitter)="personDataAction($event)"></app-user-data>
        </div>


        <!-- Show PersonData EditionForm -->
        <div *ngIf="edit">

            <div style="padding-top: 20px">
                <app-user-edit [person]="person" [me]="me" (successEmitter)="personDataAction($event)"></app-user-edit>
            </div>

            <div style="padding-top: 30p; padding-bottom: 20px">
                <app-user-rights [person]="person"></app-user-rights>
            </div>
        </div>
    </div>

</div>


<div *ngIf="me && me.rights >= 1000" style="margin-top: 30px; margin-bottom: 30px;">
    <!-- Panel "Finanzen" -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h2>Reservierungen</h2>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <my-registrations [userId]="person.UID"></my-registrations>
        </ng-template>

    </mat-expansion-panel>
</div>