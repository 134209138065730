<div class="layoutRow">

    <div>
        <h2>{{ group.name }}</h2>
        <p *ngIf="group.uid && me$ && (me$.rights >= 1000)">{{group.uid}}</p>
    </div>


    <!-- Show edit button only when current user is is content manager -->
    <div *ngIf="(me$ && (me$.rights >= 500))">
        <button mat-button color="primary" (click)="setEdit()">
            <mat-icon aria-hidden="false" aria-label="edit icon">edit</mat-icon>
        </button>
    </div>
</div>

<div class="bl-border">

    <div style="margin-left: 10px;">
        <h3>Beschreibung der Gruppe: </h3>

        <p>{{ group.description }}</p>

        <div *ngIf="!showMembers">
            <button mat-button color="primary" (click)="showMembers=true">
                <mat-icon aria-hidden="false" aria-label="more icon">more_horiz</mat-icon>
            </button>
        </div>

        <div *ngIf="showMembers">
            <bl19-group-members [uid]="group.uid"></bl19-group-members>
        </div>
    </div>

</div>