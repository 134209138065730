<div *ngIf="!cookieConsent" class="cookieConsent layoutColumn">

    <h2>...ohne <a [routerLink]="'/legal'">Cookies</a> gehts leider nicht</h2>



    <button (click)="saveConsent()" mat-raised-button style="margin-bottom: 20px;">
        <mat-icon aria-hidden="false" aria-label="save">done</mat-icon>
        Einverstanden
    </button>



    <h2>
        Einstellungen:
    </h2>

    <mat-slide-toggle [(ngModel)]="techChecked" style="margin-bottom: 50px;">Cookies zur Optimierung unserer Website
    </mat-slide-toggle>
</div>