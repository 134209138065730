import { Component, OnInit, Input } from '@angular/core';

import { bl19news } from 'src/app/app.bl.types';
import { NewsService } from 'src/app/services/news.service';


@Component({
  selector: 'bl19-news-single',
  templateUrl: './news-single.component.html',
  styleUrls: ['./news-single.component.scss']
})
export class NewsSingleComponent implements OnInit {

  @Input() news?: bl19news;
  @Input() uid?: string = 'empty';

  editMode: boolean = false;

  constructor(
    private newsServ: NewsService,
  ) { }

  ngOnInit(): void {
    if (this.uid != 'empty') {
      this.newsServ.getSingleNews(this.uid).then(res => {
        this.news = res;
      }, err => {
        console.error(err);
      });
    }
  }
}
