<div *ngIf="!groupMembers" class="blcenterelement">
    <mat-spinner></mat-spinner>
</div>


<div *ngIf="me$ && me$.rights >= 1000" style="padding-top: 20px">
    <button mat-fab color="primary" (click)="callAddMember()">
        <mat-icon aria-label="Plus Symbol">
            group_add
        </mat-icon>
    </button>
</div>


<div *ngIf="groupMembers">

    <h3>Mitglieder der Gruppe: </h3>

    <mat-list>
        <mat-list-item *ngFor="let member of groupMembers">
            <p matLine>
                <span> {{ member.name }} </span>
                <button mat-button color="primary" (click)="deleteUser(member)">
                    <mat-icon aria-label="Cancel Symbol">
                        cancel
                    </mat-icon>
                </button>
            </p>
        </mat-list-item>
    </mat-list>
</div>