import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// own Modules
import { HomeComponent } from './Components/Main/home/home.component';
import { AboutComponent } from './Components/Main/about/about.component';
import { LoginComponent } from './Components/Template/login/login.component';
import { UsersComponent } from './Components/users/users.component';
import { AuthGuard } from './Guards/auth.guard';
import { ThanksComponent } from './Components/Template/thanks/thanks.component';
import { LegalComponent } from './Components/Template/Legal/legal.component';
import { SermonComponent } from 'src/app/Components/sermon/sermon.component';
import { NewsComponent } from './Components/news/news.component';
import { MediaComponent } from './Components/Main/media/media.component';
import { ContactComponent } from './Components/Main/contact/contact.component';



const routes: Routes = [

  // access without authentication:
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'sermon', component: SermonComponent },
  { path: 'news', component: NewsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'media', component: MediaComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'ce', redirectTo: '/news?uid=5tbSJcHQlAJsoGYwc2nf' },
  { path: 'bko', redirectTo: '/news?uid=2LqIaeuUeJ0uezQwsGhy' },


  { path: 'thanks', component: ThanksComponent },
  { path: 'legal', component: LegalComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

