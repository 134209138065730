<div style="height: 100%;">
    <div class="who-we-are-text">
        <div>
            <h1>Bibelgemeinde Günzburg</h1>
        </div>
        <mat-divider class="bl-section-content"></mat-divider>
        <div>
            <h3>
                Wir nennen uns Bibelgemeinde.
                Das bedeutet nicht, dass wir uns für besser oder biblischer halten als andere Christen.
                Es soll vielmehr ein Anspruch und eine Aufforderung an uns selber sein.
                Wir halten die Bibel für das geschriebene Wort unseres Schöpfers.
                Deshalb gibt es keine bessere Adresse, wenn wir Fragen zum Leben haben.
            </h3>

            <h3>
                Der Kirchenvater Augustinus (4. Jhd.) sagte: Ecclesia semper reformanda. (Lateinisch: die Kirche muss
                beständig reformiert werden)
                So wollen wir als Kirchengemeinde immer wieder in Gottes Wort schauen und daraus Leben für uns schöpfen.
            </h3>
        </div>
    </div>
</div>