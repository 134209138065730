<bl19-toolbar [transparent]="false"></bl19-toolbar>

<div class="loginStyle">

    <!-- to not get content under the toolbar -->
    <div style="height: 60px"></div>


    <div style="padding:10%">

        <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value"
            (selectedIndexChange)="selected.setValue($event)">

            <!-- Login Tab -->
            <mat-tab label="Login">

                <div style="margin-top: 30px; margin-bottom: 20px">
                    <app-mail-login (successEmitter)="onLoginCompleted()"></app-mail-login>
                </div>

                <mat-divider></mat-divider>

                <div style="margin-top: 50px; margin-bottom:50px">
                    <app-provider (successEmitter)="onLoginCompleted()"></app-provider>
                </div>

            </mat-tab>



            <!-- Register Tab -->
            <mat-tab label="Registrieren">

                <div style="margin-top: 30px; margin-bottom: 20px">
                    <app-mail-register (successEmitter)="onLoginCompleted()"></app-mail-register>
                </div>


                <mat-divider></mat-divider>


                <div style="margin-top: 50px; margin-bottom:50px">
                    <app-provider (successEmitter)="onLoginCompleted()"></app-provider>
                </div>

            </mat-tab>


            <!-- Forgott PW Tab -->
            <mat-tab *ngIf="seeForgotPW" label="neues PW">

                <div style="margin-top: 30px">
                    <app-mail-forgotten></app-mail-forgotten>
                </div>

            </mat-tab>

        </mat-tab-group>

    </div>

</div>
