import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import firebase from 'firebase/compat/app';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics';


@Component({
  selector: 'bl19-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  constructor(
    private cookieService: CookieService,
    private analytics: AngularFireAnalytics,
    ) { }

  cookieConsent: boolean = false;
  cookieTech: boolean = false;


  techChecked: boolean = true;


  ngOnInit(): void {

    this.analytics.setAnalyticsCollectionEnabled(false);

    // read  cookies if cookie consent has been given
    if (this.cookieService.get('cookieConsent') == 'true') {
      this.cookieConsent = true;

      if (this.cookieService.get('cookieTech') == 'true') {
        this.cookieTech = true;

        // only call analytics if cookie consent is given by the user
        setTimeout(() => {
          this.callFirebaseAnalytics();
        }, 2000);
      }
    } else {
      this.cookieService.deleteAll();
    }
  }



  saveConsent() {
    // safe cookie consent
    if (this.techChecked) {
      this.cookieService.set('cookieTech', 'true', 366);
      setTimeout(() => {
        this.callFirebaseAnalytics();
      }, 2000);
    } else {
      this.cookieService.set('cookieTech', 'false', 366);
    }


    this.cookieService.set('cookieConsent', 'true', 365);
    this.cookieConsent = true;
  }





  callFirebaseAnalytics() {
    this.analytics.setAnalyticsCollectionEnabled(true);
  }

}
