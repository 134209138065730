import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';

import Cropper from "cropperjs";




@Component({
  selector: 'bl19-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit {

  // +++++++++++++++++++++++++++++++++
  // For Photo Cropping:
  @ViewChild('image', { static: false })
  public imageElement: ElementRef;


  @Input('src')
  public imageSource: string;

  @Input() fileName: string;


  @Output() emitFile = new EventEmitter<File>();


  public imageDestination: Blob;
  public fileExtension: string;

  private cropper: Cropper;

  public canvas: any;


  constructor() { }

  ngOnInit() {
  }

  public ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    this.fileExtension = this.imageSource.substring("data:image/".length, this.imageSource.indexOf(";base64"));

    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      //minContainerHeight: 500,
      crop: () => {
        this.canvas = this.cropper.getCroppedCanvas();
      }
    });
  }


  saveImage() {
    var reader = new FileReader();

    this.canvas.toBlob((blob) => {

      let newPic: any = blob;

      // give missing attributes to convert from blob to file
      newPic.lastModifiedDate = new Date();
      newPic.name = this.fileName + "." + this.fileExtension;

      // emit the converted blob as "File"
      this.emitFile.emit(newPic);
    }, 'image/' + this.fileExtension);
  }
}
