import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


import { AuthService } from '../../../services/auth.service';
import { UsersService } from '../../../services/users.service';
import { bl19personOwn } from '../../../app.bl.types';


@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.scss']
})
export class MeComponent implements OnInit {

  me$: Observable<bl19personOwn>;

  edit: boolean = false;

  constructor(
    private authServ: AuthService,
    private usersServ: UsersService,
    private router: Router) { }

  ngOnInit() {
    this.me$ = this.authServ.getMeObs();
  }





  callLogout() {
    this.authServ.doLogout().then(res => {
      this.router.navigate(['/home']);
    }, err => console.log('Error on Logout: ' + err));
  }



  applyMe(apply: string) {
    if (apply) {
      const obj: bl19personOwn = {
        role: apply
      }
      this.usersServ.updatePerson(obj).then(res => {
        this.authServ.downloadMe();
      });
    }
  }
}
