import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadPhotoComponent } from 'src/app/dialogs/upload-photo/upload-photo.component';


import { UsersService } from '../../../services/users.service';
import { bl19personOwn } from '../../../app.bl.types';





@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  @Input() person: bl19personOwn;
  @Input() me: bl19personOwn;

  @Output() successEmitter = new EventEmitter<string>();

  updatePersonForm: UntypedFormGroup;
  updatePersonData: bl19personOwn = {};

  constructor(private usersServ: UsersService,
    private formB: UntypedFormBuilder,
    private dialog: MatDialog) { }

  ngOnInit() {



    this.updatePersonForm = this.formB.group({
      name: ['', []],
      bDay: ['', []],
      phone: ['', []],
      street: ['', []],
      city: ['', []],
      postalCd: ['', []],
    });

  }


  cancelEdit() {
    this.successEmitter.emit("editModeOff");
  }

  // for easy access to form:
  get f() { return this.updatePersonForm.controls };


  updatePerson() {
    var changed: boolean = false;

    if (this.f.name.value) {
      this.updatePersonData.name = this.f.name.value;
      changed = true;
    }

    if (this.f.bDay.value) {
      this.updatePersonData.bDay = this.f.bDay.value;
      changed = true;
    }

    if (this.f.phone.value) {
      this.updatePersonData.phone = this.f.phone.value;
      changed = true;
    }

    if (this.f.street.value) {
      this.updatePersonData.street = this.f.street.value;
      changed = true;
    }

    if (this.f.city.value) {
      this.updatePersonData.city = this.f.city.value;
      changed = true;
    }

    if (this.f.postalCd.value) {
      this.updatePersonData.postalCd = this.f.postalCd.value;
      changed = true;
    }

    if (changed) {
      this.usersServ.updatePerson(this.updatePersonData, this.person.UID).then(res => this.successEmitter.emit("editModeOff"));
    }

  }



  dialogUploadPhoto(): void {

    const pathToPut = 'users/u_default_groups/own_data/' + this.person.UID + '/';
    const fileName = '_thumbNeed_profilePic';

    const dialogRef = this.dialog.open(UploadPhotoComponent, {
      width: '95%',
      height: '100 vh',
      data: { path: pathToPut, fileName: fileName, type: "image" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });

  }

}
