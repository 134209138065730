<form [formGroup]="registerForm" class="login-form"  style="padding:10px">

    <!-- Email -->
    <mat-form-field>
        <input matInput placeholder="EMail" formControlName="email">
        <div *ngIf="f.email.errors && (f.email.dirty || f.email.touched)">
            <mat-error *ngIf="f.email.errors.required">
                Eine Email-Adresse wird <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="!f.email.errors.required">
                Bitte gebe eine gültige EMail-Adresse an!
            </mat-error>
        </div>
    </mat-form-field>


    <!-- Passwort -->
    <mat-form-field>
        <input matInput placeholder="Passwort" formControlName="pw" type="password">
        <div *ngIf="f.pw.errors && (f.pw.dirty || f.pw.touched)">
            <mat-error *ngIf="f.pw.errors.required">
                Ein Passwort wird <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="!f.pw.errors.required && f.pw.errors">
                Das Passwort muss mindestens sechs Stellen haben.
            </mat-error>
        </div>
    </mat-form-field>


    <!-- confirm Passwort -->
    <mat-form-field style="padding-bottom: 20px">
        <input matInput placeholder="Passwort" formControlName="confirmPw" type="password">
        <div *ngIf="f.confirmPw.errors && (f.confirmPw.dirty || f.confirmPw.touched)">
            <mat-error *ngIf="registerForm.hasError('notSame')">
                Passwörter stimmen nicht überein...
            </mat-error>
        </div>
    </mat-form-field>


    <mat-checkbox formControlName="dataLaw" style="padding-bottom: 50px">
        Durch die Registrierung <br>
        stimmen Sie unserer <br>
        <a routerLink="/legal">Datenschutzerklärung</a> zu.
    </mat-checkbox>




    <!-- register button -->
    <div >
        <span fxFlex="auto"></span>
        <button (click)="register()" mat-raised-button [disabled]="(registerForm.invalid)">Registrieren</button>
        <span fxFlex="auto"></span>
    </div>

</form>