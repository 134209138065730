import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { bl19personOwn } from '../../app.bl.types';
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  constructor(private authServ: AuthService) { }

  ngOnInit() {
    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });
  }



  ngOnDestroy(): void {
    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }
}
