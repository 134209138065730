import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { bl19personOwn, bl19group } from 'src/app/app.bl.types';
import { AuthService } from 'src/app/services/auth.service'
import { Subscription } from 'rxjs';


@Component({
  selector: 'bl19-group-data',
  templateUrl: './group-data.component.html',
  styleUrls: ['./group-data.component.scss']
})
export class GroupDataComponent implements OnInit {

  @Input() group: bl19group
  @Output() editEmitter = new EventEmitter<boolean>();

  showMembers: boolean = false;

  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  constructor(private authServ: AuthService) { }

  ngOnInit() {
    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }



  setEdit() {
    this.editEmitter.emit(true);
  }

}
