import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bl19-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})

export class WhoWeAreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
