<div class="backgroundPic" style="background-color: black;" [ngStyle]="{'background-image': getBackgroundURL()}">

    <div class="layoutRow">
        <div class="layoutColumn" style="padding-top: 40vh; padding-bottom: 10vh; max-width: 90%;">
            <div *ngIf="news.heading" class="bl-welcome-bright">
                <h1>
                    {{news.heading}}
                </h1>
            </div>

            <div *ngIf="news.text" class="bl-welcome-bright">
                <mat-divider class="bl-divider-white"></mat-divider>
            </div>

            <div *ngIf="news.text" class="bl-welcome-bright">
                <h2>
                    {{news.text}}
                </h2>
            </div>

            <!-- <button *ngIf="news.link" mat-button (click)="useLink(news.link)" class="bl-welcome-bright">
                <mat-icon aria-hidden="false" aria-label="link icon">link</mat-icon>
            </button> -->

            <!-- Show edit button only when current user is is content manager -->
            <div style="margin: auto">
                <button *ngIf="(me$ && (me$.rights >= 500))" mat-button (click)="setEdit()" class="bl-welcome-bright">
                    <mat-icon aria-hidden="false" aria-label="edit icon">edit</mat-icon>
                </button>
            </div>

            <div *ngIf="newsRichTextObject.richText && newsRichTextObject.richText != ''"
                style="margin-top: 20px; margin-bottom: 50px;">
                <div class="newsText" [innerHtml]='newsRichTextObject.richText'>
                    {{newsRichTextObject.richText}
                </div>
                <!-- mat-card style=" margin-top: 20px;" [innerHtml]='newsRichTextObject.richText'>
                </mat-card -->
            </div>
        </div>
    </div>
</div>