<div class="backgroundPic" [ngStyle]="{'background-image': getBackgroundURL()}">
    <div class="sermon-container">
        <div class="sermon-card">
            <div class="sermon-header">
                <div class="meta-info">
                    <h3>{{ sermon.type }} {{ sermon.date | date }}</h3>
                    <h3>{{ sermon.preacherName }}</h3>
                </div>

                <img *ngIf="sermon.preacherObject?.thumb_picture"
                    [src]="sermon.preacherObject.thumb_picture" 
                    [alt]="sermon.preacherName"
                    class="preacher-image">
            </div>

            <div class="sermon-title">
                <h1>{{ sermon.title }}</h1>
                <h3>{{ sermon.book }} {{ chapterVerse }}</h3>
            </div>

            <!-- Show edit button only when current user is content manager -->
            <button *ngIf="me$ && me$.rights >= 500" 
                mat-icon-button 
                (click)="setEdit()"
                class="edit-button">
                <mat-icon aria-label="edit icon">edit</mat-icon>
            </button>

            <div class="sermon-content">
                <mat-tab-group mat-stretch-tabs dynamicHeight *ngIf="sermon.link && sermon.videoLink">
                    <mat-tab label="Audio">
                        <audio controls>
                            <source [src]="sermon.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </mat-tab>

                    <mat-tab label="Video">
                        <iframe 
                            [width]="activeRatio.width" 
                            [height]="activeRatio.height"
                            [src]="sermon.videoLink | safe" 
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                    </mat-tab>
                </mat-tab-group>

                <div *ngIf="sermon.link && !sermon.videoLink">
                    <audio controls>
                        <source [src]="sermon.link" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </div>

                <div *ngIf="sermon.videoLink && !sermon.link">
                    <iframe 
                        [width]="activeRatio.width" 
                        [height]="activeRatio.height"
                        [src]="sermon.videoLink | safe" 
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</div>