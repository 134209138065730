import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { AuthService } from '../../../../services/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-mail-login',
  templateUrl: './mail-login.component.html',
  styleUrls: ['./mail-login.component.scss']
})
export class MailLoginComponent implements OnInit {

  @Output() successEmitter = new EventEmitter();

  loginForm: UntypedFormGroup;

  constructor(private authServ: AuthService,
    private formB: UntypedFormBuilder) { }

  ngOnInit() {
    this.loginForm = this.formB.group({
      email: ['', [Validators.required, Validators.email]],
      pw: ['', [Validators.required]]
    });
  }


  // for easy access to form:
  get f() { return this.loginForm.controls };


  login() {
    this.authServ.doLogin(this.f.email.value, this.f.pw.value).then(res => this.successEmitter.emit(), err => { console.log(err) });
  }

}
