import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit {

  @Output() successEmitter = new EventEmitter();


  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authServ: AuthService,
  ) {

    // for icons of different providers
    this.matIconRegistry.addSvgIcon(
      "google",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/provider/google.svg")
    );
  }

  ngOnInit() {
  }


  login(provider: string) {
    this.authServ.chooseProviderLogin(provider).then(res => this.successEmitter.emit(), err => { console.log(err) });
  }

}
