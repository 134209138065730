<form [formGroup]="resetForm">

    <!-- Email -->
    <mat-form-field>
        <input matInput placeholder="EMail" formControlName="email">
        <div *ngIf="f.email.errors && (f.email.dirty || f.email.touched)">
            <mat-error *ngIf="f.email.errors.required">
                Eine Email-Adresse wird <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="!f.email.errors.required">
                Bitte gebe eine gültige EMail-Adresse an!
            </mat-error>
        </div>
    </mat-form-field>


    <!-- send email for pw-reset button -->
    <div>
        <span fxFlex="auto"></span>
        <button (click)="resetPW()" mat-raised-button [disabled]="(resetForm.invalid)">Neues Passwort anfordern</button>
        <span fxFlex="auto"></span>
    </div>


</form>