import { Component, OnInit, Input } from '@angular/core';

import { UsersService } from '../../../services/users.service';
import { bl19personOwn, bl19userRights, bl19userRight } from 'src/app/app.bl.types';



export interface stateRights {
  right: string,
  state: boolean
}


@Component({
  selector: 'app-user-rights',
  templateUrl: './user-rights.component.html',
  styleUrls: ['./user-rights.component.scss']
})



export class UserRightsComponent implements OnInit {

  @Input() person: bl19personOwn

  rightsList: bl19userRight[] = bl19userRights;

  beginRights: bl19userRight;
  changedRights: bl19userRight;

  changesMade: boolean = false;

  constructor(private usersServ: UsersService) { }

  ngOnInit() {
    const idx = this.rightsList.findIndex(element => element.level == this.person.rights);
    this.beginRights = this.rightsList[idx];
    this.changedRights = this.rightsList[idx];
  }

  setRights() {
    this.usersServ.setUserRights(this.changedRights.level, this.person.UID);
  }

  selected(level: number) {
    const idx = this.rightsList.findIndex(element => element.level == level);
    this.changedRights = this.rightsList[idx];

    if (this.beginRights !== this.changedRights) {
      this.changesMade = true;
    } else {
      this.changesMade = false;
    }
  }
}
