import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { bl19news, bl19singleQuery } from 'src/app/app.bl.types';

import { NewsService } from 'src/app/services/news.service';
import { element } from 'protractor';

@Component({
  selector: 'bl19-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  @Output() newsEmitter = new EventEmitter<bl19news>();
  @Input() inNewsQuery: bl19singleQuery;
  @Input() numberOfNews: number = 5;


  searchQuery: Array<bl19singleQuery> = [];
  lastNews: bl19news = null;
  noMoreNews: boolean = false;

  newsList: Array<bl19news> = [];



  constructor(private newsServ: NewsService) { }

  ngOnInit(): void {
    if (this.inNewsQuery) {
      this.searchQuery.push(this.inNewsQuery);
    }

    this.newsServ.getAllNewsByQuery(this.searchQuery, this.numberOfNews).then(res => {
      if (res[0]) {
        this.newsList = res;
        this.lastNews = this.newsList[this.newsList.length - 1];
      }
      if (res.lenght < this.numberOfNews) {
        this.noMoreNews = true;
      }
    });
  }


  goToNews(choosenNews: bl19news) {
    this.newsEmitter.emit(choosenNews);
    window.scroll(0, 0);
  }

  loadMoreSermons() {
    this.newsServ.getAllNewsByQuery(this.searchQuery, this.numberOfNews, this.lastNews.dateUpdated).then(res => {
      if (res[0]) {
        res.forEach(element => {
          this.newsList.push(element);
        });

        this.lastNews = this.newsList[this.newsList.length - 1];

        if (res.length < this.numberOfNews) {
          this.noMoreNews = true;
        }
      }
    });
  }
}
