import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { bl19sermonData, bl19personOwn, ytRatio } from 'src/app/app.bl.types';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'bl19-sermon-single-data',
  templateUrl: './sermon-single-data.component.html',
  styleUrls: ['./sermon-single-data.component.scss']
})






export class SermonSingleDataComponent implements OnInit {

  @Input() sermon: bl19sermonData;
  @Output() editEmitter = new EventEmitter<boolean>();



  bl19ratio: Array<ytRatio> = [
    {
      size: 'big',
      width: 560,
      height: 315
    },
    {
      size: 'medium',
      width: 360,
      height: 155
    },
    {
      size: 'small',
      width: 300,
      height: 140,
    },
  ]

  activeRatio: ytRatio = {
    size: 'small',
    width: 640,
    height: 360
  };

  subSize: Subscription;

  backgroundPicURL: string = "https://firebasestorage.googleapis.com/v0/b/bibel-leben-dev.appspot.com/o/assets%2Fheadphone.jpg?alt=media&token=78aeac94-3c1a-4c93-a87e-2b1e4eea2878";


  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  chapterVerse: string = "";

  constructor(private authServ: AuthService) {

  }

  ngOnInit() {

    this.setBestRatio();

    // greater than 760, change to big window...
    // less than 760, change to smaller window...
    // less than 500, change to mini window
    this.subSize = fromEvent(window, 'resize').subscribe(x => {
      this.setBestRatio();
    });

    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });

    if (this.sermon.chapterStart) {
      this.chapterVerse = this.sermon.chapterStart.toString();

      if (this.sermon.verseStart) {
        this.chapterVerse = this.chapterVerse + "; " + this.sermon.verseStart.toString();

        if (this.sermon.chapterEnd && (this.sermon.chapterEnd != this.sermon.chapterStart)) {
          this.chapterVerse = this.chapterVerse + " bis " + this.sermon.chapterEnd.toString() + "; ";
        } else {
          this.chapterVerse = this.chapterVerse + " - "
        }


        if (this.sermon.verseEnd) {
          this.chapterVerse = this.chapterVerse + this.sermon.verseEnd.toString();
        }
      }
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }

    if (this.subSize) {
      this.subSize.unsubscribe();
    }
  }


  setEdit() {
    this.editEmitter.emit(true);
  }

  getBackgroundURL() {

    if (this.sermon.picture) {
      this.backgroundPicURL = this.sermon.picture;
    }
    return "url(" + this.backgroundPicURL + ")";
  }

  setBestRatio() {

    if (window.innerWidth >= 760) {
      this.activeRatio = this.bl19ratio.find(i => i.size === 'big')
    }

    if ((window.innerWidth < 760) && (window.innerWidth >= 500)) {
      this.activeRatio = this.bl19ratio.find(i => i.size === 'medium')
    }

    if (window.innerWidth < 500) {
      this.activeRatio = this.bl19ratio.find(i => i.size === 'small');
    }
  }

}
