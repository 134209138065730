import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { bl19sermonData, bl19personOwn } from 'src/app/app.bl.types';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'bl19-sermon',
  templateUrl: './sermon.component.html',
  styleUrls: ['./sermon.component.scss'],
})

export class SermonComponent implements OnInit {

  // Input values when getting to this page:
  inSUID: string;
  inSeries: string;

  inSermon: bl19sermonData = null;

  subParam: Subscription;

  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  addSermon: boolean = false;

  // Query Strings
  preacherQuery: string;
  topicQuery: string;
  seriesQuery: string;


  constructor(
    private route: ActivatedRoute,
    private authServ: AuthService
  ) { }


  ngOnInit() {
    this.subParam = this.route.params.subscribe(params => {
      this.inSUID = this.route.snapshot.queryParams['suid'] || 'empty';
      this.inSeries = this.route.snapshot.queryParams['SeriesUID'] || 'empty';
    });


    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });

  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subParam.unsubscribe();

    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }

  newSermon(newSermon: bl19sermonData) {
    this.inSUID = 'empty';
    this.inSermon = null;

    // need to do this, to reset the Audio-Player,
    // otherwise the player will still hold the old sermon
    setTimeout(() => this.inSermon = newSermon, 1);
  }

  latestSermon(latestSermon: bl19sermonData) {
    if ((this.inSUID == 'empty') && this.inSermon === null) {
      this.inSermon = latestSermon;
    }
  }
}