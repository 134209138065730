<div class="navigation-footer">

  <div class="layoutRow">


    <div  style="margin: auto;" class="footerElement">
      <h2>Bibelgemeinde</h2>
      <p>
        Maria Theresia Straße 2 <br>
        89312 Günzburg<br>
      </p>
      <p>
        info&#64;bibel-leben.de
      </p>
    </div>

    <div style="margin: auto;" class="footerElement">
      <h2>Links</h2>
      <p><a routerLink="/legal">Datenschutz und Impressum</a></p>
      <p><a href="https://christen-in-gz.de/">Christengemeinde in Günzburg</a></p>
    </div>

    <div style="margin: auto;" class="footerElement">
      <span>
        <a href="https://www.youtube.com/channel/UCvVertDVWILhmVsIdDKa9tA">
          <img src="../../../../assets/icons/youtube.svg" alt="youtube" class="social-media-icon">
        </a>
        <a href="https://bibel-leben.church.tools/">
          <img src="../../../../assets/icons/churchtools-logo.svg" alt="churchtools" class="social-media-icon">
        </a>
        <!--a href="https://bibel-leben.bibelkunde.online/">
          <img src="../../../../assets/icons/bko-logo-white.svg" alt="Gemeindebibelschule" class="social-media-icon">
        </a-->
  
        <a href="https://www.instagram.com/bibelgemeinde_guenzburg/">
          <img src="../../../../assets/icons/instagram.svg" alt="Gemeindebibelschule" class="social-media-icon">
        </a>
  
        
        <a href="https://open.spotify.com/show/1WIxjZBFJXM2hvx5PaGqmu">
          <img src="../../../../assets/icons/spotify.svg" alt="Spotify" class="social-media-icon">
        </a>
        <a
          href="https://podcasts.google.com/?feed=aHR0cHM6Ly9ldXJvcGUtd2VzdDMtYmliZWwtbGViZW4tZGV2LmNsb3VkZnVuY3Rpb25zLm5ldC9yc3NHb29nbGU&ep=14">
          <img src="../../../../assets/icons/google-podcasts.svg" alt="Gemeindebibelschule"
            class="social-media-icon">
        </a>
      </span>
     
    </div>

  </div>

  <div class="layoutRow">
    <div>
      <p class="footer-copyright">© 2022 Copyright | Bibel-Leben</p>
    </div>
  </div>
</div>