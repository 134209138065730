<div class="bl19LightBackground">

    <bl19-toolbar [transparent]="false"></bl19-toolbar>

    <div style="padding-top: 100px"></div>


    <div class="layoutColumn">

        <div style="max-width: 70%">
            <app-impressum></app-impressum>
        </div>

        <div style="max-width: 70%">
            <app-datenschutz></app-datenschutz>
        </div>

        <div style="max-width: 70%">
            <bl19-thanks></bl19-thanks>
        </div>

    </div>

</div>