import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';

import { bl19news, bl19personOwn, bl19dataRichText } from 'src/app/app.bl.types';
import { NewsService } from 'src/app/services/news.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'bl19-news-single-data',
  templateUrl: './news-single-data.component.html',
  styleUrls: ['./news-single-data.component.scss']
})

export class NewsSingleDataComponent implements OnInit, OnDestroy {

  @Input() news: bl19news;
  @Output() editEmitter = new EventEmitter<boolean>();


  newsRichTextObject: bl19dataRichText = {
    richText: ''
  }

  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  constructor(
    private authServ: AuthService,
    private newsServ: NewsService,
  ) { }

  ngOnInit(): void {

    this.getRichText();

    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });
  }

  getRichText() {
    this.newsServ.getSingleNewsRichText(this.news.uid).then(res => {
      this.newsRichTextObject.richText = res.richText;
    }, err => {
      console.error('Error on downloading newsRichText: ' + err);
    });
  }


  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    this.newsRichTextObject.richText = '';
    this.getRichText();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }

  setEdit() {
    this.editEmitter.emit(true);
  }

  useLink(url: string) {
    window.location.href = url;
  }

  getBackgroundURL() {
    return "url(" + this.news.picture + ")";
  }

}
