import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { AuthService } from '../../../../services/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-mail-forgotten',
  templateUrl: './mail-forgotten.component.html',
  styleUrls: ['./mail-forgotten.component.scss']
})
export class MailForgottenComponent implements OnInit {

  @Output() successEmitter = new EventEmitter();

  resetForm: UntypedFormGroup;

  constructor(private authServ: AuthService,
    private formB: UntypedFormBuilder) { }

  ngOnInit() {
    this.resetForm = this.formB.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // for easy access to form:
  get f() { return this.resetForm.controls };


  resetPW() {
    this.authServ.doResetPassword(this.f.email.value).then(res => this.successEmitter.emit(), err => { console.log(err) });
  }
}
