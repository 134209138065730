import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { SermonService } from 'src/app/services/sermon.service'
import { bl19singleQuery, bl19BibleBooks, bl19typeSermon, bl19personSmall } from 'src/app/app.bl.types'

@Component({
  selector: 'bl19-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit, OnDestroy {

  @Output() queryEmitter = new EventEmitter<bl19singleQuery[]>();


  editQuery: UntypedFormGroup;

  choosenQuery: bl19singleQuery[] = [];
  lastQuery: bl19singleQuery[] = [];

  subOnChange: Subscription;
  changesMade: boolean = false;


  // ----------------------------------
  // Data for choosing the query
  sermonTypes: string[] = bl19typeSermon;

  preachersObjects: bl19personSmall[] = [];
  preachers: string[] = [];

  bibleBooks: string[] = bl19BibleBooks;
  // -----------------------------------




  constructor(private sermServ: SermonService,
    private formB: UntypedFormBuilder) { }


  // for easy access to form:
  get f() { return this.editQuery.controls }




  ngOnInit(): void {
    // ------------------------------------
    // sermonTypes
    // add "---" if i want to search for all types
    this.sermonTypes.push('---');


    // ------------------------------------
    // BibleBooks
    // add "---" if i want to search in all books
    this.bibleBooks.push('---');


    // ------------------------------------
    // Preachers
    // get list of preachers to fill options
    this.sermServ.getPreachers().then(res => {
      this.preachersObjects = res;

      this.preachersObjects.forEach(elem => {
        this.preachers.push(elem.name);
      });

      // add "---" if i want to search for all preachers 
      this.preachers.push('---');
    });

    this.editQuery = this.formB.group({
      sermonType: ['---', []],
      preacherName: ['---', []],
      bibleBook: ['---', []]
    });

    // register subscription to changes in the Form
    this.onChanges();
  }

  onChanges(): void {
    this.subOnChange = this.editQuery.valueChanges.subscribe(val => {
      this.changesMade = true;
    });
  }

  ngOnDestroy(): void {
    if (this.subOnChange) {
      this.subOnChange.unsubscribe();
    }
  }


  activateQuery() {

    // reset Query
    this.choosenQuery = [];

    if (this.f.sermonType.value != "" && (this.f.sermonType.value != "---")) {
      this.choosenQuery.push({
        parameter: 'type',
        value: this.f.sermonType.value
      });
    }

    if (this.f.preacherName.value != "" && (this.f.preacherName.value != "---")) {
      this.choosenQuery.push({
        parameter: 'preacherName',
        value: this.f.preacherName.value
      });
    }

    if (this.f.bibleBook.value != "" && (this.f.bibleBook.value != "---")) {
      this.choosenQuery.push({
        parameter: 'book',
        value: this.f.bibleBook.value
      });
    }

    if (this.choosenQuery != this.lastQuery) {
      console.log(this.choosenQuery);
      this.queryEmitter.emit(this.choosenQuery);
    } else {
      console.log("QueryComponent: Query has not changed...");
    }

  }
}
