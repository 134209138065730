<div *ngIf="!leaders.length" class="blcenterelement">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="leaders.length">
    <div class="container">

        <mat-card class="blitem" *ngFor="let leader of leaders">
            <mat-card-header>
                <h1>{{ leader.name }}</h1>
            </mat-card-header>

            <!-- Show picture if it is not initial, empty value (val) -->
            <div *ngIf="leader.picture!='val'">
                <img mat-card-image src="{{ leader.picture }}">
            </div>

            <mat-card-content>
                <h3>{{ leader.role }}</h3>
            </mat-card-content>

        </mat-card>
    </div>
</div>