import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { AuthService } from '../services/auth.service';
import { bl19personOwn } from '../app.bl.types';



@Injectable({
  providedIn: 'root'
})
export class UsersService {



  private membersArr: Array<bl19personOwn> = [];
  private _membersArr = new BehaviorSubject<Array<bl19personOwn>>(null);

  private refMembersColl: AngularFirestoreCollection<bl19personOwn>;


  private allUsers: Array<bl19personOwn> = [];
  private _allUsers = new BehaviorSubject<Array<bl19personOwn>>(null);

  private refAllUsersColl: AngularFirestoreCollection<bl19personOwn>;


  private _personRef: AngularFirestoreDocument<bl19personOwn> = null;


  private meSub: Subscription;
  private me: bl19personOwn;



  constructor(
    private afs: AngularFirestore,
    private authServ: AuthService) {
    this.refMembersColl = afs.collection<bl19personOwn>('users/u_default_groups/intern_data');
    this.refAllUsersColl = afs.collection<bl19personOwn>('users/u_default_groups/own_data',
      ref => ref.where('rights', '<', 50));

    this.meSub = this.authServ.getMeObs().subscribe(me => {
      this.me = me;
    });
  }



  updatePerson(data: bl19personOwn, uid?: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      let refData: string = "";
      if (uid) {
        refData = "users/u_default_groups/own_data/" + uid;
      } else {
        refData = "users/u_default_groups/own_data/" + this.me.UID;
      }

      this._personRef = this.afs.doc<bl19personOwn>(refData);
      this._personRef.update(data).then(res => {

        // toDo: find a better way - only update what has been changed...
        if (this.me.rights >= 1000) {
          this.downloadAllUsers();
        }

        this.authServ.downloadMe();

        resolve();
      }, err => {
        console.log('usersService: error on updating person data: ' + err);
        reject(err);
      });
    });
  }


  // private function to download collection
  private downloadMembers() {
    // clear array before loading the new data
    this.membersArr = [];

    this.refMembersColl.get().toPromise().then(res => {
      res.docs.forEach(doc => {
        this.membersArr.push(doc.data());
      });
      this._membersArr.next(this.membersArr);

    }, err => console.log(err));
  }


  // private function to download collection
  private downloadAllUsers() {
    // clear array before loading the new data
    this.allUsers = [];

    this.refAllUsersColl.get().toPromise().then(res => {
      res.docs.forEach(doc => {
        this.allUsers.push(doc.data());
      });
      this._allUsers.next(this.allUsers);

    }, err => console.log(err));
  }






  // creates an observable on the members array
  // to be used in the application
  getListsObs(location: string): Observable<any> {

    switch (location) {


      case "membersFriendsFull":


        // if members Array is empty -> download the data
        if (this.membersArr.length < 1 || this.membersArr === undefined) {
          this.downloadMembers();
        }
        // return the observable
        return this._membersArr.asObservable();
        break


      case "allUserOwn":
        // if members Array is empty -> download the data
        if (this.allUsers.length < 1 || this.allUsers === undefined) {
          this.downloadAllUsers();
        }
        // return the observable
        return this._allUsers.asObservable();
        break

      default:
        break;
    }
  }


  setUserRights(level: number, uid: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      let refData: string = "users/u_default_rights/accessRights/" + uid;

      this._personRef = this.afs.doc<any>(refData);
      this._personRef.set({ rights: level }).then(res => {

        // toDo: find a better way - only update when something has been changed...
        // only admins will be able to use this function, so download all user-Lists
        this.downloadAllUsers();

        resolve();
      }, err => {
        console.log("usersService, setUserRights failed to set level: " + level + " for user: " + uid);
        reject(err);
      });
    });
  }



  setRoleUser(data: bl19personOwn, uid: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      let refData: string = "users/u_default_groups/intern_data/" + uid;


      this._personRef = this.afs.doc<bl19personOwn>(refData);
      this._personRef.set(data).then(res => {

        // update role in userOwn - to see it in list 
        let refDataOwn: string = "users/u_default_groups/own_data/" + uid;
        this._personRef = this.afs.doc<bl19personOwn>(refDataOwn);
        this._personRef.update(data).then(res => {

          // toDo: find a better way - only update when something has been changed...
          // only admins will be able to use this function, so download all user-Lists
          this.downloadAllUsers();

          resolve();

        }, err => {
          console.log('usersService: error on set person role in OwnList: ' + err);
          // not so bad - this will be done by functions,
          reject();
        });

      }, err => {
        console.log('usersService: error on set person role in InternList: ' + err);
        reject(err);
      });
    });
  }




}
