<div class="bl19LightBackground">

    <bl19-toolbar [transparent]="false"></bl19-toolbar>

    <div style="padding-top: 100px"></div>


    <div class="layoutRow" style="align-items: flex-start; justify-content: space-evenly;">

        <div style="margin: 50px;">
            <app-me></app-me>
        </div>


        <div *ngIf="me$ && me$.rights >= 1000" style="margin: 50px">
            <h2>Gruppen: </h2>
            <bl19-groups></bl19-groups>
        </div>

        <div *ngIf="me$" style="margin: 50px">
            <div *ngIf="me$ && me$.rights >= 1000">
                <h2>Neue User:</h2>
                <app-members [location]="'allUserOwn'"></app-members>
            </div>

            <div *ngIf="me$.rights >= 50" style="padding-top: 50px" style="margin: 50px">
                <h2>Mitglieder und Freunde:</h2>
                <app-members [location]="'membersFriendsFull'"></app-members>
            </div>
        </div>
    </div>

    <div style="padding-bottom: 50px"></div>


    <div style="padding-bottom: 50px"></div>

</div>