import { Injectable, OnDestroy } from '@angular/core';
import { Auth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, confirmPasswordReset, signOut, User } from '@angular/fire/auth';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { bl19personOwn } from '../app.bl.types';
import { Unsubscribe } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private userObserver: Unsubscribe;
  private _currentUser = new BehaviorSubject<User | null>(null);
  private _currentUserData = new BehaviorSubject<bl19personOwn | null>(null);

  constructor(
    private auth: Auth,
    private firestore: Firestore
  ) {
    this.userObserver = auth.onAuthStateChanged((user) => {
      if (user && user.uid) {
        console.log("AuthService: Subscription: user is logged in");
        this._currentUser.next(user);

        if (!this._currentUserData.value) {
          this.downloadMe();
        }
      } else {
        console.log("AuthService: Subscription: user is not logged in");
        this._currentUser.next(null);
        this._currentUserData.next(null);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userObserver) {
      this.userObserver();
    }
  }

  async downloadMe(): Promise<void> {
    if (this._currentUser.value) {
      const userUid = this._currentUser.value.uid;
      const meRef = doc(this.firestore, `users/u_default_groups/own_data/${userUid}`);
      try {
        const docSnap = await getDoc(meRef);
        if (docSnap.exists()) {
          this._currentUserData.next(docSnap.data() as bl19personOwn);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.log('authService: error on downloading me data: ' + err);
      }
    }
  }

  getMeObs(): Observable<bl19personOwn | null> {
    return this._currentUserData.asObservable();
  }

  getMe(): bl19personOwn | null {
    return this._currentUserData.value;
  }

  getUser(): User | null {
    return this._currentUser.value;
  }

  get getUserObs(): Observable<User | null> {
    return new Observable((observer) => {
      this.auth.onAuthStateChanged(observer);
    });
  }

  async chooseProviderLogin(provider: string): Promise<void> {
    try {
      let loginProvider;
      switch (provider) {
        case 'google':
          loginProvider = new GoogleAuthProvider();
          loginProvider.addScope('profile');
          loginProvider.addScope('email');
          await this.doProviderLogin(loginProvider);
          break;
        default:
          throw new Error('auth.service: provider not available');
      }
    } catch (error) {
      console.error('auth.service: error in provider login', error);
    }
  }

  private async doProviderLogin(provider: GoogleAuthProvider): Promise<void> {
    await signInWithPopup(this.auth, provider);
  }

  async doRegister(email: string, password: string): Promise<User> {
    const userCredential = await createUserWithEmailAndPassword(this.auth, email, password);
    return userCredential.user;
  }

  async doLogin(email: string, password: string): Promise<User> {
    const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
    return userCredential.user;
  }

  async doResetPassword(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  async doConfirmPasswordReset(code: string, password: string): Promise<void> {
    await confirmPasswordReset(this.auth, code, password);
  }

  async doLogout(): Promise<void> {
    await signOut(this.auth);
    this._currentUserData.next(null);
  }
}
