<div style="text-align: center; margin-top: 10% ">
    <h1>Die nächsten Termine:</h1>
</div>


<div class="container">
    <mat-card class="blitem" *ngFor="let day of datesWithEvents">
        <mat-card-header>
            <div>
                <h2 style="margin-bottom: 1px;">{{day | date:'EEEE'}}</h2>
                <p style="margin-top: 0px">{{day | date:'dd.MMM'}}</p>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div *ngFor="let event of nextEvents">
                <div *ngIf="event.startDate.getDate() === day.getDate()" style="margin-top: 40px;">
                    <h3 style="margin-bottom: 1px;">{{event.name}}</h3>
                    <p style="padding-left: 10px; margin-top: 0px">{{event.startDate | date:'HH:mm'}}</p>

                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>