<div class="sermon-edit-container">
    <mat-card class="sermon-edit-card">
        <mat-card-header *ngIf="sermon">
            <mat-card-title *ngIf="sermon.suid">Predigt ID: {{ sermon.suid }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="editSermonForm" class="sermon-form">
                <!-- Show on Welcome Page Toggle -->
                <div class="form-row">
                    <mat-slide-toggle formControlName="showOnWelcomePage" [checked]="editSermonData.showOnWelcomePage">
                        Auf Startseite anzeigen
                    </mat-slide-toggle>
                </div>

                <!-- Basic Info Section -->
                <div class="form-section">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Titel der Predigt</mat-label>
                        <input matInput formControlName="title" [placeholder]="editSermonData.title" />
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Datum</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker"
                            formControlName="date"
                            [placeholder]="editSermonData.date | date : 'dd.MM.yyyy'"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <!-- Bible Reference Section -->
                <div class="form-section bible-reference">
                    <div class="bible-book">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Bibelstelle</mat-label>
                            <mat-select formControlName="book">
                                <mat-option *ngFor="let book of bibleBooks" [value]="book">
                                    {{ book }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="verse-inputs">
                        <div class="verse-row">
                            <mat-form-field appearance="outline">
                                <mat-label>Kapitel</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    formControlName="chStart"
                                    [placeholder]="editSermonData.chapterStart"
                                    (ngModelChange)="changeChapter($event)"
                                />
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Vers</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    formControlName="verseStart"
                                    [placeholder]="editSermonData.verseStart"
                                />
                            </mat-form-field>
                        </div>

                        <div class="verse-row">
                            <mat-form-field appearance="outline">
                                <mat-label>bis Kapitel</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    formControlName="chEnd"
                                    [placeholder]="editSermonData.chapterEnd"
                                />
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>bis Vers</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    formControlName="verseEnd"
                                    [placeholder]="editSermonData.verseEnd"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- Preacher Section -->
                <div class="form-section">
                    <div class="form-row">
                        <mat-slide-toggle
                            formControlName="guestPrForm"
                            (change)="editSermonData.guestPreacher = $event.checked"
                        >
                            Gast Prediger
                        </mat-slide-toggle>
                    </div>

                    <ng-container *ngIf="editSermonData.guestPreacher">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Name des Gastpredigers</mat-label>
                            <input
                                matInput
                                formControlName="preacherName"
                                [placeholder]="editSermonData.preacherName"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="!editSermonData.guestPreacher">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Prediger auswählen</mat-label>
                            <mat-select formControlName="preacherObject">
                                <mat-option *ngFor="let preacher of preachers" [value]="preacher">
                                    {{ preacher.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </div>

                <!-- Sermon Type -->
                <div class="form-section">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Typ der Predigt</mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let type of sermonTypes" [value]="type">
                                {{ type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Media Section -->
                <div class="form-section">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>YouTube Video Code</mat-label>
                        <input matInput formControlName="videoLink" [placeholder]="editSermonData.videoLink" />
                        <mat-hint>Nur den YouTube-Code eingeben (z.B. dQw4w9WgXcQ)</mat-hint>
                    </mat-form-field>

                    <!-- Audio Upload -->
                    <div *ngIf="sermon?.suid" class="media-upload">
                        <div *ngIf="!sermon.link" class="upload-button">
                            <button mat-raised-button color="primary" (click)="dialogUploadFile()">
                                <mat-icon>cloud_upload</mat-icon>
                                Audio hochladen
                            </button>
                        </div>

                        <div *ngIf="sermon.link" class="audio-player">
                            <audio controls>
                                <source [src]="sermon.link" type="audio/mpeg" />
                                Ihr Browser unterstützt das Audio-Element nicht.
                            </audio>
                            <span *ngIf="sermon.fileSize" class="file-size">
                                Dateigröße: {{ sermon.fileSize | number }} Bytes
                            </span>
                        </div>
                    </div>

                    <!-- Photo Upload -->
                    <div *ngIf="sermon?.suid" class="media-upload">
                        <button mat-raised-button color="primary" (click)="dialogUploadPhoto()">
                            <mat-icon>image</mat-icon>
                            Foto hochladen
                        </button>

                        <div *ngIf="sermon?.picture" class="sermon-image">
                            <img [src]="sermon.picture" alt="Predigt Foto" class="preview-image" />
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>

        <mat-card-actions align="end">
            <button mat-button color="warn" (click)="deleteSermon()" *ngIf="sermon?.suid">
                <mat-icon>delete</mat-icon>
                Löschen
            </button>
            <button mat-button (click)="closeEdit()">
                <mat-icon>clear</mat-icon>
                Abbrechen
            </button>
            <button mat-raised-button color="primary" (click)="editSermon()" *ngIf="changesMade">
                <mat-icon>save</mat-icon>
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>
</div>
