import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';

registerLocaleData(localeDE, 'de');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// rich text editor
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';




// neccessary for angular material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider'
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { HttpClientModule } from '@angular/common/http';


import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAnalyticsModule, CONFIG, ScreenTrackingService  } from '@angular/fire/compat/analytics';

import { CookieService } from 'ngx-cookie-service';


import { ToolbarComponent } from './Components/Template/toolbar/toolbar.component';
import { HomeComponent } from './Components/Main/home/home.component';
import { AboutComponent } from './Components/Main/about/about.component';
import { FooterComponent } from './Components/Template/footer/footer.component';
import { WhoWeAreComponent } from './Components/Content/who-we-are/who-we-are.component';
import { InformationComponent } from './Components/Content/information/information.component';
import { LeadersComponent } from './Components/Lists/leaders/leaders.component';
import { LoginComponent } from './Components/Template/login/login.component';
import { MailLoginComponent } from './Components/Template/login/mail-login/mail-login.component';
import { MailRegisterComponent } from './Components/Template/login/mail-register/mail-register.component';
import { MailForgottenComponent } from './Components/Template/login/mail-forgotten/mail-forgotten.component';
import { ProviderComponent } from './Components/Template/login/provider/provider.component';
import { UsersComponent } from './Components/users/users.component';
import { MeComponent } from './Components/users/me/me.component';
import { MembersComponent } from './Components/Lists/members/members.component';
import { UserComponent } from './Components/users/user/user.component';
import { ThanksComponent } from './Components/Template/thanks/thanks.component';
import { ImpressumComponent } from './Components/Template/Legal/impressum/impressum.component';
import { DatenschutzComponent } from './Components/Template/Legal/datenschutz/datenschutz.component';
import { LegalComponent } from './Components/Template/Legal/legal.component';
import { UserEditComponent } from './Components/users/user-edit/user-edit.component';
import { UserDataComponent } from './Components/users/user-data/user-data.component';
import { UserRightsComponent } from './Components/users/user-rights/user-rights.component';
import { SermonComponent } from './Components/sermon/sermon.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { SermonSingleDataComponent } from './Components/sermon/sermon-single-data/sermon-single-data.component';
import { SermonSingleEditComponent } from './Components/sermon/sermon-single-edit/sermon-single-edit.component';
import { SermonListComponent } from './Components/sermon/sermon-list/sermon-list.component';
import { SermonSingleComponent } from './Components/sermon/sermon-single/sermon-single.component';
import { GroupsComponent } from './Components/groups/groups.component';
import { GroupSingleComponent } from './Components/groups/group-single/group-single.component';
import { GroupEditComponent } from './Components/groups/group-edit/group-edit.component';
import { GroupDataComponent } from './Components/groups/group-data/group-data.component';
import { GroupMembersComponent } from './Components/groups/group-members/group-members.component';
import { AddFromPersonOwnComponent } from './Components/users/add-from-person-own/add-from-person-own.component';
import { UploadFileComponent } from './dialogs/upload-file/upload-file.component';
import { SermonListElementComponent } from './Components/sermon/sermon-list-element/sermon-list-element.component';
import { UploadPhotoComponent } from './dialogs/upload-photo/upload-photo.component';
import { CropperComponent } from './dialogs/upload-photo/cropper/cropper.component';
import { NewsComponent } from './Components/news/news.component';
import { NewsCarouselComponent } from './Components/news/news-carousel/news-carousel.component';
import { NewsSingleComponent } from './Components/news/news-single/news-single.component';
import { NewsSingleDataComponent } from './Components/news/news-single-data/news-single-data.component';
import { NewsSingleEditComponent } from './Components/news/news-single-edit/news-single-edit.component';
import { NewsListComponent } from './Components/news/news-list/news-list.component';
import { NewsListElementComponent } from './Components/news/news-list-element/news-list-element.component';
import { MediaComponent } from './Components/Main/media/media.component';
import { EventsComponent } from './Components/events/events.component';
import { SafePipe } from './pipes/safe.pipe';
import { QueryComponent } from './Components/sermon/query/query.component';
import { CookieConsentComponent } from './Components/Template/cookie-consent/cookie-consent.component';
import { ContactComponent } from './Components/Main/contact/contact.component';
import { ContactMapComponent } from './Components/Content/contact-map/contact-map.component';
import { provideFirebaseApp } from '@angular/fire/app';
import { initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';






@NgModule({
    declarations: [
        AppComponent,
        ToolbarComponent,
        HomeComponent,
        MediaComponent,
        AboutComponent,
        FooterComponent,
        WhoWeAreComponent,
        InformationComponent,
        LeadersComponent,
        LoginComponent,
        MailLoginComponent,
        MailRegisterComponent,
        MailForgottenComponent,
        ProviderComponent,
        UsersComponent,
        MeComponent,
        MembersComponent,
        UserComponent,
        ThanksComponent,
        ImpressumComponent,
        DatenschutzComponent,
        LegalComponent,
        UserEditComponent,
        UserDataComponent,
        UserRightsComponent,
        SermonComponent,
        DropZoneDirective,
        FileSizePipe,
        SermonSingleDataComponent,
        SermonSingleEditComponent,
        SermonListComponent,
        SermonSingleComponent,
        GroupsComponent,
        GroupSingleComponent,
        GroupEditComponent,
        GroupDataComponent,
        GroupMembersComponent,
        AddFromPersonOwnComponent,
        UploadFileComponent,
        SermonListElementComponent,
        UploadPhotoComponent,
        CropperComponent,
        NewsComponent,
        NewsCarouselComponent,
        NewsSingleComponent,
        NewsSingleDataComponent,
        NewsSingleEditComponent,
        NewsListComponent,
        NewsListElementComponent,
        EventsComponent,
        SafePipe,
        QueryComponent,
        CookieConsentComponent,
        ContactComponent,
        ContactMapComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        // rich text editor
        RichTextEditorAllModule,

        // Angular fire
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireAnalyticsModule,
        // Angular Material
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MatButtonModule,
        MatDividerModule,
        MatGridListModule,
        MatCardModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatTabsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatTableModule,
        MatProgressBarModule,
        MatSelectModule,
        MatListModule,
        MatDialogModule,
        MatRadioModule,
        MatMenuModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        HttpClientModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore())
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE'},
        { provide: CONFIG, useValue: {
            send_page_view: true,
            allow_ad_personalization_signals: false,
            anonymize_ip: true,
        }},
        ScreenTrackingService,
        CookieService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
