<div class="sermon-element">
    <img
        *ngIf="sermon.preacherObject?.thumb_picture"
        [src]="sermon.preacherObject.thumb_picture"
        [alt]="sermon.preacherName"
        class="preacher-image"
    />
    
    <div class="sermon-header">
        <h3>{{ sermon.title }}</h3>
    </div>
    
    <div class="sermon-meta">
        <div class="meta-info">
            <p class="date">{{ sermon.date | date : "mediumDate" }}</p>
            <p class="type">{{ sermon.type }}</p>
            <p class="reference">{{ sermon.book }} {{ chapterVerse }}</p>
            <p class="preacher">{{ sermon.preacherName }}</p>
        </div>
    </div>
</div>