import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { bl19sermonData, bl19BibleBooks, bl19personSmall, bl19typeSermon } from 'src/app/app.bl.types';
import { SermonService } from 'src/app/services/sermon.service';
import { UploadFileComponent } from 'src/app/dialogs/upload-file/upload-file.component';
import { UploadPhotoComponent } from 'src/app/dialogs/upload-photo/upload-photo.component';
import { Subscription } from 'rxjs';

// Custom date formats
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'bl19-sermon-single-edit',
  templateUrl: './sermon-single-edit.component.html',
  styleUrls: ['./sermon-single-edit.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class SermonSingleEditComponent implements OnInit, OnDestroy {
  @Input() sermon?: bl19sermonData;
  @Output() editEmitter = new EventEmitter<boolean>();

  subOnChange: Subscription;
  changesMade: boolean = false;
  editSermonForm: UntypedFormGroup;
  editSermonData: bl19sermonData = {};

  // Default sermon data for new sermons
  blankSermonData: bl19sermonData = {
    title: "Titel der Predigt",
    date: new Date(),
    guestPreacher: false,
    preacherName: "Gastprediger",
    showOnWelcomePage: true,
    chapterStart: 0,
    chapterEnd: 0,
    verseStart: 0,
    verseEnd: 0,
    type: 'Gottesdienst'
  };

  // Data arrays
  bibleBooks: string[] = bl19BibleBooks;
  preachers: Array<bl19personSmall> = [];
  sermonTypes: string[] = bl19typeSermon;

  constructor(
    private formB: UntypedFormBuilder,
    private sermServ: SermonService,
    private dialog: MatDialog,
    private dateAdapter: DateAdapter<any>
  ) {
    this.dateAdapter.setLocale('de');
  }

  ngOnInit() {
    this.initializeForm();
    this.loadPreachers();
    this.setupFormChangeListener();
  }

  ngOnDestroy() {
    if (this.subOnChange) {
      this.subOnChange.unsubscribe();
    }
  }

  private initializeForm() {
    // Initialize with either existing sermon data or blank data
    this.editSermonData = this.sermon || this.blankSermonData;

    this.editSermonForm = this.formB.group({
      title: [this.editSermonData.title || '', [Validators.required]],
      date: [this.editSermonData.date || new Date(), [Validators.required]],
      book: [this.editSermonData.book || ''],
      guestPrForm: [this.editSermonData.guestPreacher || false],
      preacherName: [this.editSermonData.preacherName || ''],
      preacherObject: [this.editSermonData.preacherObject || null],
      showOnWelcomePage: [this.editSermonData.showOnWelcomePage || true],
      chStart: [this.editSermonData.chapterStart || 0, [Validators.min(0)]],
      chEnd: [this.editSermonData.chapterEnd || 0, [Validators.min(0)]],
      verseStart: [this.editSermonData.verseStart || 0, [Validators.min(0)]],
      verseEnd: [this.editSermonData.verseEnd || 0, [Validators.min(0)]],
      videoLink: [this.editSermonData.videoLink || ''],
      type: [this.editSermonData.type || 'Gottesdienst', [Validators.required]],
    });
  }

  private loadPreachers() {
    this.sermServ.getPreachers()
      .then(res => this.preachers = res)
      .catch(err => console.error('Error loading preachers:', err));
  }

  private setupFormChangeListener() {
    this.subOnChange = this.editSermonForm.valueChanges.subscribe(() => {
      this.changesMade = true;
    });
  }

  // Convenience getter for form fields
  get f() { 
    return this.editSermonForm.controls; 
  }

  editSermon() {
    if (!this.changesMade || !this.editSermonForm.valid) {
      return;
    }

    const formValue = this.editSermonForm.value;
    const updatedSermon: bl19sermonData = {
      title: formValue.title,
      date: formValue.date.toDate(),
      book: formValue.book,
      guestPreacher: formValue.guestPrForm,
      preacherName: formValue.guestPrForm ? formValue.preacherName : formValue.preacherObject?.name,
      preacherObject: formValue.guestPrForm ? null : formValue.preacherObject,
      showOnWelcomePage: formValue.showOnWelcomePage,
      chapterStart: formValue.chStart,
      chapterEnd: formValue.chEnd,
      verseStart: formValue.verseStart,
      verseEnd: formValue.verseEnd,
      type: formValue.type,
    };

    // Add video link if provided
    if (formValue.videoLink) {
      updatedSermon.videoLink = `https://www.youtube-nocookie.com/embed/${formValue.videoLink}`;
    }

    // Update or create sermon
    const promise = this.sermon
      ? this.sermServ.updateSermon(updatedSermon, this.sermon.suid)
      : this.sermServ.updateSermon(updatedSermon);

    promise
      .then(res => {
        this.changesMade = false;
        if (!this.sermon) {
          this.sermon = res;
        }
        console.log('Sermon saved successfully');
      })
      .catch(err => console.error('Error saving sermon:', err));
  }

  dialogUploadFile() {
    if (!this.sermon?.suid) return;

    const dialogRef = this.dialog.open(UploadFileComponent, {
      width: '300px',
      data: { 
        path: `web/content/sermon/${this.sermon.suid}/`,
        fileName: 'sermon.mp3',
        type: "mp3"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadMp3UrlReady(result.url, result.file, result.fileSize, result.duration);
      }
    });
  }

  dialogUploadPhoto() {
    if (!this.sermon?.suid) return;

    const dialogRef = this.dialog.open(UploadPhotoComponent, {
      width: '95%',
      height: '90vh',
      data: { 
        path: `web/content/sermon/${this.sermon.suid}/`,
        fileName: 'sermonPicture',
        type: "image"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadPhotoUrlReady(result.url, result.file);
      }
    });
  }

  downloadMp3UrlReady(url: string, filePath: string, fileSize: number, duration: number) {
    if (!this.sermon?.suid) return;

    const updateData: bl19sermonData = {
      link: url,
      filePath: filePath,
      fileSize: fileSize,
      duration: duration,
    };

    this.sermServ.updateSermon(updateData, this.sermon.suid)
      .then(() => {
        this.sermon.link = url;
        this.sermon.filePath = filePath;
        this.sermon.fileSize = fileSize;
        this.sermon.duration = duration;
      })
      .catch(err => console.error('Error updating sermon audio:', err));
  }

  downloadPhotoUrlReady(url: string, filePath: string) {
    if (!this.sermon?.suid) return;

    const updateData: bl19sermonData = {
      picture: url,
      picturePath: filePath,
    };

    this.sermServ.updateSermon(updateData, this.sermon.suid)
      .then(() => {
        this.sermon.picture = url;
        this.sermon.picturePath = filePath;
      })
      .catch(err => console.error('Error updating sermon picture:', err));
  }

  changeChapter(newStartChapter: number) {
    if (newStartChapter > (this.f.chEnd.value || 0)) {
      this.f.chEnd.setValue(newStartChapter);
    }
  }

  closeEdit() {
    this.editEmitter.emit(false);
  }

  deleteSermon() {
    if (!this.sermon?.suid) {
      this.editEmitter.emit(false);
      return;
    }

    this.sermServ.deleteSermon(this.sermon.suid)
      .then(() => this.editEmitter.emit(false))
      .catch(err => console.error('Error deleting sermon:', err));
  }
}
