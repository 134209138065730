import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'bl19-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
